import Button from '@core-ui/button';
import Modal from '@core-ui/modal';
import TextField from '@core-ui/text_field';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { useAppSelector } from '@/hooks/useAppSelector';
import { renameNode } from '@/pages/documents/Modals/RenameNodeModal/actions';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, IntlMessageKeys } from 'components/Intl';
import { useDispatch } from 'react-redux';
import selector from './selector';

interface IProps {
  opened: boolean;
  onClose: () => void;
}

const RenameNodeModal = (props: IProps) => {
  const { opened, onClose } = props;

  const { node, nodeName, loading } = useAppSelector(selector);
  const [name, setName] = useState<string>('');

  const dispatch = useDispatch();

  useEffect(() => {
    setName(nodeName ?? '');
  }, [opened]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // TODO: переписать эту пару экшен-сага для передачи только необходимых параметров а не всей ноды
    dispatch(renameNode({ node, newName: name }));
  };

  let title: IntlMessageKeys = 'modal.documents.rename_document.title';
  let textFieldLabel: IntlMessageKeys = 'modal.documents.rename_document.document_name';

  // only folders have a droppable property set to true
  if (node?.droppable) {
    title = 'modal.documents.rename_folder.title';
    textFieldLabel = 'modal.documents.rename_folder.folder_name';
  }

  return (
    <Modal
      open={opened}
      maxWidth="xxs"
      onClose={onClose}
      title={<FormattedMessage id={title} />}
    >
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            size="medium"
            autoFocus
            name="name"
            label={<FormattedMessage id={textFieldLabel} />}
            value={name}
            // @ts-ignore
            onChange={setName}
          />

          <Grid container justifyContent="flex-end" gap={3} component={DialogActions} disableSpacing>
            <Button
              color="blue"
              size="large"
              variant="contained"
              type="submit"
              loading={loading}
              disabled={loading}
              label={<FormattedMessage id="label.save" />}
            />
          </Grid>
        </form>
      </DialogContent>
    </Modal>
  );
};

export default RenameNodeModal;
