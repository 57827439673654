import { PLACEHOLDER_POSITION_ADJUSTMENT } from '@/pages/backoffice/consts';
import { YachtSmall } from '@core-ui/icons';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'components/Intl';
import React, { CSSProperties } from 'react';

const containerStyle: CSSProperties = {
  marginTop: PLACEHOLDER_POSITION_ADJUSTMENT,
};

const SelectedNodePlaceholder = () => {
  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" sx={containerStyle}>
      <YachtSmall />

      <Grid item marginTop={8}>
        <Typography variant="h5" component="p">
          <FormattedMessage id="documents.preview.no_data" />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SelectedNodePlaceholder;
