import { useAppSelector } from '@/hooks/useAppSelector';
import CreateFolderModal from '@/pages/documents/Modals/CreateFolderModal';
import DeleteNodeModal from '@/pages/documents/Modals/DeleteNodeModal';
import RenameNodeModal from '@/pages/documents/Modals/RenameNodeModal';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { closeDocumentsModal } from './actions';
import { CreateFolderModalData, DeleteNodeModalData, RenameNodeModalData } from './command';
import selector from './selector';

const DocumentsModals = () => {
  const dispatch = useDispatch();
  const state = useAppSelector(selector);

  const onClose = useCallback(() => dispatch(closeDocumentsModal()), [dispatch]);

  return (
    <>
      <CreateFolderModal opened={state.opened && state.data instanceof CreateFolderModalData} onClose={onClose} />

      <DeleteNodeModal opened={state.opened && state.data instanceof DeleteNodeModalData} onClose={onClose} />

      <RenameNodeModal opened={state.opened && state.data instanceof RenameNodeModalData} onClose={onClose} />
    </>
  );
};

export default DocumentsModals;
