import Button from '@core-ui/button';
import { HEIGHT_SIZE } from '@core-ui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ROUTES } from '@/app/consts/routes';
import { vehicleIdSelector } from '@/app/selectors';
import { TOP_MENU_HEIGHT } from '@/app/styles/consts';
import { useAppSelector } from '@/hooks/useAppSelector';
import { client } from '@/sentry';
import { House } from '@phosphor-icons/react';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'components/Intl';
import { Link } from 'react-router-dom';
import useStyles from './styles';

const Page404 = () => {
  const boatId = useAppSelector(vehicleIdSelector);

  const styles = useStyles();

  useEffect(() => {
    client.withScope((scope) => {
      scope.setLevel('log');
      scope.setExtras({
        from: document.referrer || 'unknown',
        to: window.location.toString(),
      });

      client.captureMessage('Captured 404 page');
    });
  }, []);

  return (
    <Grid
      container
      gap={4}
      direction="column"
      justifyContent="center"
      alignItems="center"
      height={`calc(100vh - ${TOP_MENU_HEIGHT + HEIGHT_SIZE.tabsHeight}px)`}
    >
      <Grid item container wrap="nowrap" minHeight={110} justifyContent="center" alignItems="center">
        <Typography sx={styles.blackLetter}>4</Typography>

        <Typography sx={styles.rotatedLetter}>0</Typography>

        <Typography sx={styles.blackLetter}>4</Typography>
      </Grid>

      <Typography variant="h5" component="p">
        <FormattedMessage id="page404.subtitle" />
      </Typography>

      <Button
        color="blue"
        variant="contained"
        LinkComponent={Link}
        to={`/${boatId}/${ROUTES.BACKOFFICE}`}
        startIcon={<House />}
        label={<FormattedMessage id="page404.button" />}
      />
    </Grid>
  );
};

export default Page404;
