import Button from '@core-ui/button';
import Grid from '@mui/material/Grid';
import { getDocumentsSectionList } from '@/pages/documents/DocumentsList/actions';
import { ArrowClockwise, ImageSquare } from '@phosphor-icons/react';
import React from 'react';
import { FormattedMessage } from 'components/Intl';
import { useDispatch } from 'react-redux';
import useStyles from './styles';

const PreviewPlaceholder = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const handleReloadPage = () => {
    dispatch(getDocumentsSectionList(null));
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      wrap="nowrap"
      paddingBottom={6}
    >
      <Grid item container justifyContent="center" alignItems="center" height="100%" wrap="nowrap">
        <ImageSquare size={52} />
      </Grid>

      <Grid item>
        <Button
          variant="filled"
          color="black"
          size="large"
          sx={styles.reloadButton}
          onClick={handleReloadPage}
          label={<FormattedMessage id="label.update" />}
          startIcon={<ArrowClockwise />}
        />
      </Grid>
    </Grid>
  );
};

export default PreviewPlaceholder;
