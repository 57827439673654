import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';

const ListSkeleton = () => {
  return (
    <Grid container direction="column" gap={3}>
      <Skeleton height={22} width="100%" />
      <Skeleton height={22} width="100%" />
      <Skeleton height={22} width="100%" />
      <Skeleton height={22} width="100%" />
      <Skeleton height={22} width="100%" />
      <Skeleton height={22} width="100%" />
      <Skeleton height={22} width="100%" />
    </Grid>
  );
};

export default ListSkeleton;
