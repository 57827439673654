import { Nullable } from '@core-ui/types';
import Grid from '@mui/material/Grid';
import NoDataContainer from '@/components/NoDataContainer';
import NoDataPlaceholder from '@/components/NoDataPlaceholder';
import { FileStorageExpiringEnum } from '@/generated';
import { useAppSelector } from '@/hooks/useAppSelector';
import { changeSelectedNode, setSelectedNode } from '@/pages/documents/DocumentsList/actions';
import ExpireListItem from '@/pages/documents/DocumentsList/components/ExpireListItem';
import ListSkeleton from '@/pages/documents/DocumentsList/components/skeletons';
import { getExpiredTabsNoData, getExpireTabsData } from '@/pages/documents/DocumentsList/selector';
import { FileTreeNode } from '@/pages/documents/types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const ExpireSoonDocuments = () => {
  const { items, selectedNode } = useAppSelector(getExpireTabsData);

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedNode?.data?.status !== FileStorageExpiringEnum.EXPIRE_SOON) {
      dispatch(setSelectedNode(null));
    }
  }, []);

  const handleSelectNode = (node: Nullable<FileTreeNode>) => {
    dispatch(changeSelectedNode(node));
  };

  return (
    <NoDataContainer
      selector={getExpiredTabsNoData}
      loader={<ListSkeleton />}
      noDataContent={<NoDataPlaceholder subtitleId={null} />}
    >
      <Grid item container direction="column" wrap="nowrap" gap={1}>
        {items?.map((item) => (
          <ExpireListItem
            node={item}
            status={FileStorageExpiringEnum.EXPIRE_SOON}
            isSelected={item.id === selectedNode?.id}
            onSelect={handleSelectNode}
            key={item.id}
          />
        ))}
      </Grid>
    </NoDataContainer>
  );
};

export default ExpireSoonDocuments;
