import { COLOURS } from '@core-ui/styles';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import { MIME_TYPE_ENUM, DATE_FORMAT } from '@/app/consts/app';
import { convertValueToUTCDate, formatTime } from '@/app/utils/dates';
import { FileStorageExpiringEnum } from '@/generated';
import { FileTreeNode } from '@/pages/documents/types';
import { File, FileDoc, FileJpg, FilePdf, FilePng, FileXls, Folder, FolderOpen, Warning } from '@phosphor-icons/react';
import React from 'react';
import { FormattedMessage } from 'components/Intl';

interface IProps {
  node: FileTreeNode;
  isOpen: boolean;
  isSelected: boolean;
}

const NodeIcon = (props: IProps) => {
  const { node, isOpen, isSelected } = props;

  if (node.data?.status === FileStorageExpiringEnum.EXPIRED) {
    const tooltipText = node.data?.expires_at ? (
      <FormattedMessage
        id="documents.document_expired_at"
        values={{ date: formatTime(convertValueToUTCDate(node.data.expires_at), { dateFormat: DATE_FORMAT }) }}
      />
    ) : (
      <FormattedMessage id="documents.document_expired" />
    );

    return (
      <Tooltip title={tooltipText} placement="top" arrow>
        <SvgIcon htmlColor={COLOURS.Strawberry.Solid[600]}>
          <Warning />
        </SvgIcon>
      </Tooltip>
    );
  }

  if (node.data?.status === FileStorageExpiringEnum.EXPIRE_SOON) {
    const tooltipText = node.data?.expires_at ? (
      <FormattedMessage
        id="documents.document_will_expire_on"
        values={{ date: formatTime(convertValueToUTCDate(node.data.expires_at), { dateFormat: DATE_FORMAT }) }}
      />
    ) : (
      <FormattedMessage id="documents.document_will_expire_soon" />
    );

    return (
      <Tooltip title={tooltipText} placement="top" arrow>
        <SvgIcon htmlColor={COLOURS.Banana.Solid[600]}>
          <Warning />
        </SvgIcon>
      </Tooltip>
    );
  }

  let icon: JSX.Element;

  // folders always has node.droppable set to true, files always has node.droppable set to false
  if (node.droppable) {
    if (isOpen) {
      icon = <FolderOpen />;
    } else {
      icon = <Folder />;
    }
  } else {
    switch (node.data?.file_type) {
      case MIME_TYPE_ENUM.JPG:
      case MIME_TYPE_ENUM.JPEG:
        icon = <FileJpg />;
        break;
      case MIME_TYPE_ENUM.PNG:
        icon = <FilePng />;
        break;
      case MIME_TYPE_ENUM.PDF:
        icon = <FilePdf />;
        break;
      case MIME_TYPE_ENUM.DOC:
      case MIME_TYPE_ENUM.DOCX:
        icon = <FileDoc />;
        break;
      case MIME_TYPE_ENUM.XLS:
      case MIME_TYPE_ENUM.OPEN_XML_SHEET:
        icon = <FileXls />;
        break;
      default:
        icon = <File />;
    }
  }

  return <SvgIcon htmlColor={isSelected ? COLOURS.Fig.Solid[600] : COLOURS.Coconut.Solid[900]}>{icon}</SvgIcon>;
};

export default NodeIcon;
