import Grid from '@mui/material/Grid';
import ImagesVerticalCarousel from '@/components/ImagesVerticalCarousel';
import NoDataContainer from '@/components/NoDataContainer';
import { useAppSelector } from '@/hooks/useAppSelector';
import PreviewPlaceholder from '@/pages/documents/DocumentPreview/components/PreviewPlaceholder';
import SelectedNodePlaceholder from '@/pages/documents/DocumentPreview/components/SelectedNodePlaceholder';
import { getNoDocumentPreviews, getNoSelectedNode } from '@/pages/documents/DocumentPreview/selector';
import { getSelectedNode } from '@/pages/documents/DocumentsList/selector';
import React from 'react';

const DocumentPreview = () => {
  const selectedDocument = useAppSelector(getSelectedNode);

  return (
    <NoDataContainer selector={getNoSelectedNode} noDataContent={<SelectedNodePlaceholder />}>
      <NoDataContainer selector={getNoDocumentPreviews} noDataContent={<PreviewPlaceholder />}>
        <Grid item container wrap="nowrap" position="relative" marginY={6}>
          <ImagesVerticalCarousel
            defaultWidth={300}
            documentId={selectedDocument?.id}
            imageLinks={selectedDocument?.data?.image_links}
            hideDownloadButton
          />
        </Grid>
      </NoDataContainer>
    </NoDataContainer>
  );
};

export default DocumentPreview;
